import { ethers, providers } from "ethers";
import Web3 from "web3";
import { VenlyProvider } from "@venly/web3-provider";
// import WalletConnectProvider from "@walletconnect/web3-provider";

const Venly = new VenlyProvider();

const options = {
  clientId: process.env.VUE_APP_VENLY_CLIENT_ID,
  skipAuthentication: true,
  environment: process.env.VUE_APP_NETWORK === "Mainnet" ? "prod" : "sandbox",
};

const vy_provider = await Venly.createProvider(options);
const vw3_web3 = new Web3(vy_provider);
const vy_web3Provider = new providers.Web3Provider(vy_provider);

// let wwc_provider = new WalletConnectProvider({ infuraId: "9f65f2e7dc324b6fba99c874cecfbadd" });
// let wwc_web3 = new Web3(wwc_provider);
// let wc_web3Provider = new providers.Web3Provider(wwc_provider);

export default {
  install: (app) => {
    function isVenly() {
      return app.config.globalProperties.$cookies.get("connectionType") === "vy";
    }
    function isMetamask() {
      return app.config.globalProperties.$cookies.get("connectionType") === "mt";
    }

    var provider = null;
    var signer = null;
    var accounts = null;
    var blockchain = {};

    blockchain.Venly = Venly;
    blockchain.isVenly = isVenly;
    blockchain.isMetamask = isMetamask;

    blockchain.logout = async function () {
      if (isVenly()) {
        await this.Venly.logout()
      }
      app.config.globalProperties.$cookies.remove("connectionType");
    };

    blockchain.wc_provider = function () {
      if (isVenly()) {
        vy_provider.enable = async () => {
          try {
            await Venly.connect.api.getProfile;
          } catch (err) {
            await vw3_web3.eth.getAccounts();
          }
        };
        return vy_provider;
      } else {
        return vy_provider;
      }
    };
    blockchain.wc_web3 = function () {
      if (isVenly()) {
        return vw3_web3;
      } else if (isMetamask) {
        return new Web3(window.ethereum);
      } else {
        return vw3_web3;
      }
    };
    blockchain.wc_web3Provider = function () {
      if (isVenly()) {
        return vy_web3Provider;
      } else {
        return vy_web3Provider;
      }
    };
    blockchain.ethers = ethers;
    blockchain.ethereum = null;

    blockchain.getAccounts = function () {
      return accounts;
    };
    blockchain.setAccounts = function (acts) {
      accounts = acts;
    };
    blockchain.getProvider = function () {
      return provider;
    };
    blockchain.setProvider = function (provi) {
      provider = new ethers.providers.Web3Provider(provi);
      return ethers;
    };
    blockchain.getSigner = function () {
      if (signer == null) signer = provider.getSigner();
      return signer;
    };

    blockchain.toHash = function (texto) {
      if (/(0x)/.test(texto)) {
        return ethers.utils.keccak256(texto);
      } else return ethers.utils.id(texto);
    };

    blockchain.isconnected = false;

    //Vue.prototype.$Rateeee = function (methodOptions) {}
    // app.config.globalProperties.$Web3 = blockchain;
  },
};
